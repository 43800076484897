import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_rollup@3.29.4_sass@1.69.7_typescript@5.3.3_vite@4.5.1_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/opt/build/repo/middleware/01.auth.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_rollup@3.29.4_sass@1.69.7_typescript@5.3.3_vite@4.5.1_vue-tsc@1.8.27/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "init-login": () => import("/opt/build/repo/middleware/initLogin.ts")
}