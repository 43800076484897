<script setup lang="ts">
import { appDescriptionEn, appDescriptionKo, appNameEn, appNameKo, applicationNameEn, applicationNameKo, ogImageEn, ogImageKo, ogTitleEn, ogTitleKo } from '~/constants/index'

const { locale } = useI18n()
const title = (locale.value === 'en') ? applicationNameEn : applicationNameKo
const landingTitle = (locale.value === 'en') ? appNameEn : appNameKo
useSeoMeta({
  description: (locale.value === 'en') ? appDescriptionEn : appDescriptionKo,
  ogTitle: (locale.value === 'en') ? ogTitleEn : ogTitleKo,
  ogDescription: (locale.value === 'en') ? appDescriptionEn : appDescriptionKo,
  ogImage: (locale.value === 'en') ? ogImageEn : ogImageKo,
  twitterCard: 'summary_large_image',
  applicationName: (locale.value === 'en') ? applicationNameEn : applicationNameKo,
  author: 'Black Mango',
  creator: 'Black Mango',
  publisher: 'Black Mango',
  generator: 'Nuxt.js',
  robots: 'index, follow',
})
useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} | ${title}` : landingTitle
  },
})

const isLoading = ref(false)
provide('isLoading', isLoading) // https://ko.vuejs.org/guide/components/provide-inject.html#prop-drilling

onMounted(() => {
  channelTalk.loadScript()
})
</script>

<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <UNotifications class="w-[400px]" />
  <ComponentLoading /> <!-- 중요! 가장 하단에 위치시켜야됩니다.하단에 2번 랜더링됩니다. -->
</template>

<style>
* {font-family: Pretendard,'Noto Sans KR', 'sans-serif' !important; }

button {
  cursor: pointe;
}

html, body , #__nuxt{
  height: 100vh;
  margin: 0;
  padding: 0;
}

html.dark {
  background: #222;
  color: white;
}
</style>
