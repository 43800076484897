export default defineNuxtRouteMiddleware((to) => {
  const nuxt = useNuxtApp()

  const user = useSupabaseUser()
  const isLogin = user.value !== null
  const excludeUrls = [
    '/',
    '/login',
    '/signup',
    '/loading',
    '/pricing',
    '/password',
    '/update-password',
    '/enterprise-inquiry',
    '/payment/paddle',

    '/ko',
    '/ko/login',
    '/ko/signup',
    '/ko/loading',
    '/ko/pricing',
    '/ko/password',
    '/ko/update-password',
    '/ko/enterprise-inquiry',
    '/ko/payment/paddle',

    '/event/20231121',
    '/event/track',
  ]

  // 01. 로그인X && 인증필요 페이지 접근
  if (!isLogin && !excludeUrls.includes(to.path))
    return navigateTo(nuxt.$localePath('login'))

  // 02. 로그인 && 랜딩페이지 접근
  if (isLogin && to.path === '/')
    return navigateTo('/dashboard')
  if (isLogin && to.path === '/ko')
    return navigateTo('/dashboard')
})
