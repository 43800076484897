<script setup lang="ts">
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'

const isLoading = inject('isLoading')
</script>

<template>
  <Loading v-if="isLoading" v-model:active="isLoading" color="#FE9526" loader="Dots" />
</template>
