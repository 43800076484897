import { default as _91id_93uBY2DLeWghMeta } from "/opt/build/repo/pages/dashboard/[id].vue?macro=true";
import { default as indexViWjQzLqkmMeta } from "/opt/build/repo/pages/dashboard/index.vue?macro=true";
import { default as dashboardB0S6bZ6pqpMeta } from "/opt/build/repo/pages/dashboard.vue?macro=true";
import { default as enterprise_45inquirySLsuoHpEq5Meta } from "/opt/build/repo/pages/enterprise-inquiry.vue?macro=true";
import { default as _20231121B3PvDPhFtTMeta } from "/opt/build/repo/pages/event/20231121.vue?macro=true";
import { default as trackIgd9gAXQq1Meta } from "/opt/build/repo/pages/event/track.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as loadingdn01aTyLGIMeta } from "/opt/build/repo/pages/loading.vue?macro=true";
import { default as loginBXD9sq3T3sMeta } from "/opt/build/repo/pages/login.vue?macro=true";
import { default as passwordlJqr2ntuMZMeta } from "/opt/build/repo/pages/password.vue?macro=true";
import { default as paddleeL4FXCvc4hMeta } from "/opt/build/repo/pages/payment/paddle.vue?macro=true";
import { default as pricing2lLggLVj9TMeta } from "/opt/build/repo/pages/pricing.vue?macro=true";
import { default as signupQ6T3vUFXXDMeta } from "/opt/build/repo/pages/signup.vue?macro=true";
import { default as update_45passwordPOKkJHYkChMeta } from "/opt/build/repo/pages/update-password.vue?macro=true";
export default [
  {
    path: dashboardB0S6bZ6pqpMeta?.path ?? "/dashboard",
    children: [
  {
    name: _91id_93uBY2DLeWghMeta?.name ?? "dashboard-id",
    path: _91id_93uBY2DLeWghMeta?.path ?? ":id()",
    meta: _91id_93uBY2DLeWghMeta || {},
    alias: _91id_93uBY2DLeWghMeta?.alias || [],
    redirect: _91id_93uBY2DLeWghMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard/[id].vue").then(m => m.default || m)
  },
  {
    name: indexViWjQzLqkmMeta?.name ?? "dashboard",
    path: indexViWjQzLqkmMeta?.path ?? "",
    meta: indexViWjQzLqkmMeta || {},
    alias: indexViWjQzLqkmMeta?.alias || [],
    redirect: indexViWjQzLqkmMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard/index.vue").then(m => m.default || m)
  }
],
    name: dashboardB0S6bZ6pqpMeta?.name ?? undefined,
    meta: dashboardB0S6bZ6pqpMeta || {},
    alias: dashboardB0S6bZ6pqpMeta?.alias || [],
    redirect: dashboardB0S6bZ6pqpMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: enterprise_45inquirySLsuoHpEq5Meta?.name ?? "enterprise-inquiry___en",
    path: enterprise_45inquirySLsuoHpEq5Meta?.path ?? "/enterprise-inquiry",
    meta: enterprise_45inquirySLsuoHpEq5Meta || {},
    alias: enterprise_45inquirySLsuoHpEq5Meta?.alias || [],
    redirect: enterprise_45inquirySLsuoHpEq5Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/enterprise-inquiry.vue").then(m => m.default || m)
  },
  {
    name: enterprise_45inquirySLsuoHpEq5Meta?.name ?? "enterprise-inquiry___ko",
    path: enterprise_45inquirySLsuoHpEq5Meta?.path ?? "/ko/enterprise-inquiry",
    meta: enterprise_45inquirySLsuoHpEq5Meta || {},
    alias: enterprise_45inquirySLsuoHpEq5Meta?.alias || [],
    redirect: enterprise_45inquirySLsuoHpEq5Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/enterprise-inquiry.vue").then(m => m.default || m)
  },
  {
    name: _20231121B3PvDPhFtTMeta?.name ?? "event-20231121___en",
    path: _20231121B3PvDPhFtTMeta?.path ?? "/event/20231121",
    meta: _20231121B3PvDPhFtTMeta || {},
    alias: _20231121B3PvDPhFtTMeta?.alias || [],
    redirect: _20231121B3PvDPhFtTMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/event/20231121.vue").then(m => m.default || m)
  },
  {
    name: _20231121B3PvDPhFtTMeta?.name ?? "event-20231121___ko",
    path: _20231121B3PvDPhFtTMeta?.path ?? "/ko/event/20231121",
    meta: _20231121B3PvDPhFtTMeta || {},
    alias: _20231121B3PvDPhFtTMeta?.alias || [],
    redirect: _20231121B3PvDPhFtTMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/event/20231121.vue").then(m => m.default || m)
  },
  {
    name: trackIgd9gAXQq1Meta?.name ?? "event-track___en",
    path: trackIgd9gAXQq1Meta?.path ?? "/event/track",
    meta: trackIgd9gAXQq1Meta || {},
    alias: trackIgd9gAXQq1Meta?.alias || [],
    redirect: trackIgd9gAXQq1Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/event/track.vue").then(m => m.default || m)
  },
  {
    name: trackIgd9gAXQq1Meta?.name ?? "event-track___ko",
    path: trackIgd9gAXQq1Meta?.path ?? "/ko/event/track",
    meta: trackIgd9gAXQq1Meta || {},
    alias: trackIgd9gAXQq1Meta?.alias || [],
    redirect: trackIgd9gAXQq1Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/event/track.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___en",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___ko",
    path: indexlaFeZuYZkUMeta?.path ?? "/ko",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loadingdn01aTyLGIMeta?.name ?? "loading",
    path: loadingdn01aTyLGIMeta?.path ?? "/loading",
    meta: loadingdn01aTyLGIMeta || {},
    alias: loadingdn01aTyLGIMeta?.alias || [],
    redirect: loadingdn01aTyLGIMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/loading.vue").then(m => m.default || m)
  },
  {
    name: loginBXD9sq3T3sMeta?.name ?? "login___en",
    path: loginBXD9sq3T3sMeta?.path ?? "/login",
    meta: loginBXD9sq3T3sMeta || {},
    alias: loginBXD9sq3T3sMeta?.alias || [],
    redirect: loginBXD9sq3T3sMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginBXD9sq3T3sMeta?.name ?? "login___ko",
    path: loginBXD9sq3T3sMeta?.path ?? "/ko/login",
    meta: loginBXD9sq3T3sMeta || {},
    alias: loginBXD9sq3T3sMeta?.alias || [],
    redirect: loginBXD9sq3T3sMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: passwordlJqr2ntuMZMeta?.name ?? "password___en",
    path: passwordlJqr2ntuMZMeta?.path ?? "/password",
    meta: passwordlJqr2ntuMZMeta || {},
    alias: passwordlJqr2ntuMZMeta?.alias || [],
    redirect: passwordlJqr2ntuMZMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/password.vue").then(m => m.default || m)
  },
  {
    name: passwordlJqr2ntuMZMeta?.name ?? "password___ko",
    path: passwordlJqr2ntuMZMeta?.path ?? "/ko/password",
    meta: passwordlJqr2ntuMZMeta || {},
    alias: passwordlJqr2ntuMZMeta?.alias || [],
    redirect: passwordlJqr2ntuMZMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/password.vue").then(m => m.default || m)
  },
  {
    name: paddleeL4FXCvc4hMeta?.name ?? "payment-paddle___en",
    path: paddleeL4FXCvc4hMeta?.path ?? "/payment/paddle",
    meta: paddleeL4FXCvc4hMeta || {},
    alias: paddleeL4FXCvc4hMeta?.alias || [],
    redirect: paddleeL4FXCvc4hMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/payment/paddle.vue").then(m => m.default || m)
  },
  {
    name: paddleeL4FXCvc4hMeta?.name ?? "payment-paddle___ko",
    path: paddleeL4FXCvc4hMeta?.path ?? "/ko/payment/paddle",
    meta: paddleeL4FXCvc4hMeta || {},
    alias: paddleeL4FXCvc4hMeta?.alias || [],
    redirect: paddleeL4FXCvc4hMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/payment/paddle.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___en",
    path: pricing2lLggLVj9TMeta?.path ?? "/pricing",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricing2lLggLVj9TMeta?.name ?? "pricing___ko",
    path: pricing2lLggLVj9TMeta?.path ?? "/ko/pricing",
    meta: pricing2lLggLVj9TMeta || {},
    alias: pricing2lLggLVj9TMeta?.alias || [],
    redirect: pricing2lLggLVj9TMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: signupQ6T3vUFXXDMeta?.name ?? "signup___en",
    path: signupQ6T3vUFXXDMeta?.path ?? "/signup",
    meta: signupQ6T3vUFXXDMeta || {},
    alias: signupQ6T3vUFXXDMeta?.alias || [],
    redirect: signupQ6T3vUFXXDMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: signupQ6T3vUFXXDMeta?.name ?? "signup___ko",
    path: signupQ6T3vUFXXDMeta?.path ?? "/ko/signup",
    meta: signupQ6T3vUFXXDMeta || {},
    alias: signupQ6T3vUFXXDMeta?.alias || [],
    redirect: signupQ6T3vUFXXDMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: update_45passwordPOKkJHYkChMeta?.name ?? "update-password___en",
    path: update_45passwordPOKkJHYkChMeta?.path ?? "/update-password",
    meta: update_45passwordPOKkJHYkChMeta || {},
    alias: update_45passwordPOKkJHYkChMeta?.alias || [],
    redirect: update_45passwordPOKkJHYkChMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/update-password.vue").then(m => m.default || m)
  },
  {
    name: update_45passwordPOKkJHYkChMeta?.name ?? "update-password___ko",
    path: update_45passwordPOKkJHYkChMeta?.path ?? "/ko/update-password",
    meta: update_45passwordPOKkJHYkChMeta || {},
    alias: update_45passwordPOKkJHYkChMeta?.alias || [],
    redirect: update_45passwordPOKkJHYkChMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/update-password.vue").then(m => m.default || m)
  }
]