import revive_payload_client_niDdT2lXg9 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_rollup@3.29.4_sass@1.69.7_typescript@5.3.3_vite@4.5.1_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_me9nuPRojE from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_rollup@3.29.4_sass@1.69.7_typescript@5.3.3_vite@4.5.1_vue-tsc@1.8.27/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ew9yiq1hdV from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_rollup@3.29.4_sass@1.69.7_typescript@5.3.3_vite@4.5.1_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_uiOygmnnXP from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+supabase@1.1.5_rollup@3.29.4/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.mjs";
import check_outdated_build_client_RC4uuMSBS5 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_rollup@3.29.4_sass@1.69.7_typescript@5.3.3_vite@4.5.1_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_jhFDdc0WxE from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@3.29.4_typescript@5.3.3_vue@3.4.8/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_Yh8FaQ6Gvt from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_rollup@3.29.4_sass@1.69.7_typescript@5.3.3_vite@4.5.1_vue-tsc@1.8.27/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import composition_Ti0o2s7erE from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@3.29.4_vue@3.4.8/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_tRRPdYVEym from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.0.0_rollup@3.29.4_vue@3.4.8/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import colors_wZaLnV46G4 from "/opt/build/repo/node_modules/.pnpm/@nuxt+ui@2.11.1_nuxt@3.9.1_rollup@3.29.4_vite@4.5.1_vue@3.4.8/node_modules/@nuxt/ui/dist/runtime/plugins/colors.mjs";
import plugin_client_aMSXXTX8Pv from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2_rollup@3.29.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import unocss_MzCDxu9LMj from "/opt/build/repo/.nuxt/unocss.mjs";
import chunk_reload_client_ikmdZGTlZI from "/opt/build/repo/node_modules/.pnpm/nuxt@3.9.1_eslint@8.56.0_rollup@3.29.4_sass@1.69.7_typescript@5.3.3_vite@4.5.1_vue-tsc@1.8.27/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_scroll_client_aTKK5QDZSS from "/opt/build/repo/plugins/01.scroll.client.ts";
export default [
  revive_payload_client_niDdT2lXg9,
  unhead_me9nuPRojE,
  router_ew9yiq1hdV,
  supabase_client_uiOygmnnXP,
  check_outdated_build_client_RC4uuMSBS5,
  plugin_vue3_jhFDdc0WxE,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Yh8FaQ6Gvt,
  composition_Ti0o2s7erE,
  i18n_tRRPdYVEym,
  colors_wZaLnV46G4,
  plugin_client_aMSXXTX8Pv,
  unocss_MzCDxu9LMj,
  chunk_reload_client_ikmdZGTlZI,
  _01_scroll_client_aTKK5QDZSS
]