export const appNameEn = 'Mango Picker | Web Automation, Scraper'
export const appNameKo = '망고피커 | 웹 크롤링, 스크래핑'

export const ogTitleEn = 'Mango Picker | Web Automation, Scraper'
export const ogTitleKo = '망고피커 | 웹 크롤링, 스크래핑'

export const applicationNameEn = 'Mango Picker'
export const applicationNameKo = '망고피커'

export const ogImageKo = 'https://mangopicker-public.s3.ap-northeast-2.amazonaws.com/og_image_ko.png'
export const ogImageEn = 'https://mangopicker-public.s3.ap-northeast-2.amazonaws.com/og_image_en.png'

export const appDescriptionEn = 'No Code Automation Tool. Very Easy. MangoPicker automate website tasks and repetitive operations on all websites and web applications.'
export const appDescriptionKo = '반복 작업을 자동화해주는 Mango Picker와 함께 업무 시간을 획기적으로 줄이세요. 망고 피커로 웹 크롤링과 웹 스크래핑 모두 경험해보세요.'
